<script>
import CList from '@/components/list/index.js'
import NoData from '@/components/no-data'

export default {
  name: 'list-group',
  components: {
    CList,
    NoData
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    getList () {
      return this.config.getList
    },
    renderItem () {
      return this.config.render
    }
  },
  methods: {
    onLoad () {
      const page = this.page
      this.loading = true
      this.error = false
      this.finishedText = '没有更多了'
      this.noData = false
      this.getList({
        page: this.refreshing ? 1 : page + 1
      })
        .then(res => {
          this.disabled = false

          if (this.refreshing) {
            this.list = []
            this.refreshing = false
            this.page = 1
          } else {
            this.page = page + 1
          }
          const { list, total } = res

          this.list.push(...list)

          const length = this.list.length
          this.finished = length >= total
          this.noData = length === 0
          if (this.noData) {
            this.finishedText = ''
          }
        })
        .catch(err => {
          console.error(err)
          this.error = true
          this.refreshing = false
          this.noData = false
          if (this.list.length === 0) {
            this.disabled = true
          }

          this.page = page
        })
        .finally(() => {
          this.loading = false
        })
    },
    onRefresh () {
      this.finished = false
      this.refreshing = true
      this.onLoad()
    },
    searchRefresh () {
      this.finished = false
      this.list = []
      this.page = 0
      this.onLoad()
    },
    tabOnload () {
      if (this.list.length === 0 && !this.noData) {
        this.onLoad()
      }
    }
  },
  render (h) {
    const {
      disabled,
      loading,
      finished,
      refreshing,
      error,
      noData,
      finishedText,
      list,
      onLoad,
      onRefresh,
      renderItem
    } = this
    return (
      <c-list
        class="c-list-group"
        value={loading}
        disabled={disabled}
        finished={finished}
        refreshing={refreshing}
        error={error}
        finished-text={finishedText}
        on-load={onLoad}
        on-refresh={onRefresh}
      >
        {list.map(item => renderItem(item))}
        {noData && <no-data />}
      </c-list>
    )
  },
  data () {
    return {
      list: [],
      disabled: false,
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      noData: false,
      finishedText: '没有更多了',
      page: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.c-list-group {
  min-height: 100%;
}
</style>
