<template>
  <div class="urban-manage-window">
    <div class="content-wrapper">
      <van-search
        class="card-box search-wrapper"
        background="#fff"
        v-model.trim="searchKey"
        placeholder="请输入地点进行搜索"
        @search="onSearch">
        <i class="iconfont iconsousuo" slot="left-icon"></i>
      </van-search>
      <div class="list-wrapper" ref="listWrapper">
        <ListGroup
          ref="List"
          :config="config"
        ></ListGroup>
        <!-- <scroll-list
          :page="page"
          :pageSize="pageSize"
          :total="total"
          :data="data"
          :getData="getData">
          <div class="card-box"
            v-for="item in data"
            :key="item.idCcCityManageWindow">
            <div class="desc-wrapper border-bottom-1px">
              <div class="desc">
                <h4 class="title">{{item.orgName}}</h4>
                <p class="phone">{{item.tellPhone}}</p>
              </div>
              <div class="navigate-icon" @click="handleNavigate(item)"></div>
            </div>
            <p class="address"><img src="@/assets/deptposition@3x.png" class="dept-position mr6"/>{{item.addr}}</p>
          </div>
        </scroll-list> -->
      </div>
    </div>
  </div>
</template>

<script>
import wx from 'wx'
import getWxSignature from '@/api/getWxSignature'
import API from '@/api/index'
import ListGroup from '@/components/list-group/index.js'
const { getCgWindowList } = API
export default {
  name: 'urbanManageWindow',
  data () {
    return {
      data: [], // 分页数据
      total: -1,
      page: 1,
      pageSize: 10,
      searchKey: '',
      config: {
        getList: (params) => this.getData(params, ''),
        render: (item) => this.renderItem(item)
      }
    }
  },
  created () {
    getWxSignature(['openLocation'])
  },
  methods: {
    renderItem (item) {
      return (
        <div class="card-box"
          key={ item.idCcCityManageWindow }>
          <div class="desc-wrapper border-bottom-1px">
            <div class="desc">
              <h4 class="title">{item.orgName}</h4>
              <p class="phone">联系电话：{item.tellPhone}</p>
            </div>
            <div class="navigate-icon" on-click={ () => this.handleNavigate(item) }></div>
          </div>
          <p class="address"><img src={require('@/assets/deptposition@3x.png')} class="dept-position mr6"/>{item.addr}</p>
        </div>
      )
    },
    onSearch () {
      this.$refs.listWrapper.scrollTop = 0
      this.$refs.List.searchRefresh()
    },
    // 获取城管大队列表
    async getData ({ page }) {
      return new Promise((resolve, reject) => {
        getCgWindowList({
          start: page,
          limit: this.pageSize,
          orgName: this.searchKey
        }).then(({ data, success }) => {
          if (success) {
            resolve({ list: data, total: data.length })
          } else {
            reject(new Error())
          }
        })
      })
    },
    // 导航
    handleNavigate ({ latitude, longitude, orgName, addr }) {
      wx.openLocation({
        latitude, // 纬度，浮点数，范围为90 ~ -90
        longitude, // 经度，浮点数，范围为180 ~ -180。
        name: orgName, // 位置名
        address: addr // 地址详情说明
      })
    }
  },
  components: {
    // 'scroll-list': () => import('@/components/ScrollList.vue')
    ListGroup
  }
}
</script>

<style lang="scss" scoped>
  @import '@/common/css/mixin';
  .urban-manage-window{
    .search-wrapper{
      height: 72px;
      margin-bottom: 24px;
      padding: 20px 28px;
      padding-right: 0;
      box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.08);
      .iconfont{
        font-size: 28px;
        color: #999;
      }
    }
    .list-wrapper{
      height: calc(100% - 120px);
      overflow: auto;
      .card-box{
        padding: 36px 28px;
        font-size: 28px;
        &:not(:last-child) {
          margin-bottom: 24px;
        }
        .desc-wrapper{
          @include flexCenter(space-between);
          padding-bottom: 24px;
          margin-bottom: 24px;
          .title{
            color: #333;
            margin-bottom: 24px;
          }
          .phone{
            color: #999;
          }
        }
        .address{
          color: #333;
          line-height: 1.5;
        }
        .van-icon{
          vertical-align: middle;
        }
        .navigate-icon{
          @include bgImage('../../assets/navigation', 60px, 60px);
        }
        .van-icon {
          margin-right: 8px;
        }
        .dept-position{
          width: 32px;
          height: 32px;
          vertical-align: text-bottom;
        }
      }
    }
    .cell{
      margin-bottom: 20px;
    }
  }
</style>

<style lang="scss">
  .urban-manage-window{
    .van-search__content{
      color: #BDC3C6;
      background: #fff;
      padding: 0;
    }
  }
</style>
