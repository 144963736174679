<script>
// van-list 有几个 bug
// 1. 加载中文字不显示，自己写了个加载中的元素
// 2. 多次触发 load ，使用 value(loading) 值来限制 load 事件
// 3. on-input 事件多次触发赋值问题，判断 value(loading) 与 v 的值是否相同来避免
// 本组件在 van-list 的基础上封装，解决这几个问题
import { PullRefresh, List } from 'vant'

export default {
  name: 'c-list',
  components: {
    'van-pull-refresh': PullRefresh,
    'van-list': List
  },
  props: {
    refreshing: Boolean,
    value: Boolean,
    finished: Boolean,
    error: Boolean,
    disabled: Boolean,
    finishedText: {
      type: String,
      default: '没有更多了'
    }
  },
  render () {
    const {
      disabled,
      refreshing,
      value,
      finished,
      error,
      finishedText,
      $slots
    } = this
    const $emit = this.$emit.bind(this)
    return (
      <van-pull-refresh
        disabled={disabled}
        value={refreshing}
        on-refresh={() => $emit('refresh')}
      >
        <van-list
          class="c-list"
          value={value}
          finished={finished}
          finished-text={finishedText}
          on-input={v => {
            v !== value && $emit('input', v)
          }}
          on-load={() => {
            !value && $emit('load')
          }}
        >
          {$slots.default}
          {value && !refreshing && (
            <div class="van-list__finished-text">加载中...</div>
          )}
          {error && (
            <div
              class="van-list__finished-text"
              on-click={() => {
                $emit('load')
              }}
            >
              请求失败，点击重新加载
            </div>
          )}
        </van-list>
      </van-pull-refresh>
    )
  }
}
</script>

<style lang="scss">
.c-list {
  .van-list__finished-text {
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
  }
}
</style>
