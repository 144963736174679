<script>
import noDataSvg from './no-data.svg'
export default {
  name: 'c-no-data',
  props: {
    type: {
      type: String,
      default: 'default'
    },
    message: {
      type: String,
      default: '暂无数据'
    }
  },
  render () {
    const { type, message } = this
    return (
      <div class="no-data">
        <img src={noDataSvg} />
        <p class={['msg', `msg-${type}`]}>{message}</p>
      </div>
    )
  }
}
</script>

<style lang="scss" scoped>
.no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 72px;
  .msg {
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    line-height: 40px;
    &.msg-info {
      color: #ffffff;
    }
  }
}
</style>
